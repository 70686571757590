@import "./0_mediaquery.scss";
@import "./1_variables.scss";

.data {
    height: 100vh;
    overflow-y: auto;
}

.dcontainer {
    max-width: 85%;
    width: 100%;
    margin: 4.5em auto 4.5em auto;
}

.content{
    padding: 5px;
    height: 100%;
    .top{
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-gap: 10px;
        gap: 10px;
        margin-bottom: 10px;

        .yr{
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 10px;
            gap: 10px;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            h4{
                padding: 0px !important;
                display: block;
                margin: auto;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
                font-weight: 400;
                font-size: small;
            }
            select {
                height: fit-content;
                padding: 5px;
                border-bottom: 1px solid orange;
                border-top: none;
                border-left: none;
                border-right: none;
                color: white;
                outline: none;
                background-color: $accent;
                cursor: pointer;
                margin: auto;
            }
        }

        form{
            display: grid;
            grid-template-columns: 1fr auto;
            
            input{
                height: fit-content;
                padding: 5px;
                border-bottom: 1px solid orange;
                border-top: none;
                border-left: none;
                color: white;
                border-right: none;
                outline: none;
                background-color: #8f7682;
                cursor: pointer;
                margin: auto;
            }
            input::placeholder {
                opacity: 1;
                color: white;
            }
            i{
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
                padding: 5px;
                background-color: transparent;
                color: white;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                margin-left: -28px;
            }
        }
    }
    .list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2em;
        gap: 2em;
        .stk {
            position: relative;
            cursor: pointer;
  
            .edit {
              position: absolute;
              top: 10px;
              right: 10px;
              z-index: 99;
              background-color: white;
              cursor: pointer;
              padding: 5px;
              border-radius: 5px;
              color: $secondary;
            }
            .edit:hover {
              background-color: $secondary;
            }
  
            img {
              width: 100%;
              max-height: 100%;
              height: 300px;
              border-radius: 10px;
              object-fit: cover;
              object-position: top;
              border: 1px solid #60606030;
              box-shadow: 2px 2px 5px #60606050;
            }
  
            .tp {
              h3 {
                color: $secondary;
                display: block;
                margin: auto;
                width: 100%;
              }
            }
  
            .np {
              margin: -3em 1em 0 -1em;
              position: relative;
              background: linear-gradient(-60deg, #fdeedc 10%, $bg1);
              border-radius: 5px;
              padding: 1em;
              border: 1px solid #60606030;
              box-shadow: 1px 1px 5px #60606030;
              transition: transform 0.3s ease-in-out;
  
              h3 {
                color: black;
                display: block;
                margin: auto;
                width: 100%;
                font-weight: 500;
                font-size: small;
                overflow: hidden;
                line-height: 16px;
                height: 32px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
  
              h4 {
                margin: 5px 0 5px 0;
                font-weight: 500;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                color: $primary;
                font-size: x-small;
              }
  
              p {
                font-size: x-small;
                margin: 3px 0 3px 0;
                overflow: hidden;
                line-height: 14px;
                height: 56px;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }
  
              a {
                display: block;
                margin-left: auto;
                width: fit-content;
                cursor: pointer;
                text-decoration: none;
                font-size: small;
                color: blue;
                i {
                  color: blue;
                }
              }
              a:hover {
                color: blueviolet !important;
                i {
                  color: blueviolet !important;
                }
              }
            }
  
            p {
              line-height: 1.7;
              color: gray;
              font-size: small;
              margin-bottom: 10px;
            }
  
            a {
              display: block;
              margin-left: auto;
              width: fit-content;
              cursor: pointer;
              font-size: small;
              color: $secondary;
              text-decoration: none;
              i {
                color: $secondary;
              }
            }
            a:hover {
              i {
                color: $primary !important;
              }
            }
          }
          .stk:hover {
            .np {
              transform: translateX(1em);
            }
          }
    }
}