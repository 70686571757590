.timeseriesslider {
    position: absolute;
    z-index: 20;
    padding: 20px;
    max-width: 70vw;
    width: 100%;
    height: 20px;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;

    &::before {
        content: '';
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translateX(-50%);
        width: 85%;
        height: 2px;
        background: black;
    }

    .leftBtn {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 50px;
        width: 50px;
        background: none;
        border: none;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .rightBtn {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
        width: 40px;
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .timelineContainer {
        position: absolute;
        width: 80%;
        height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .startDate {
            position: absolute;
            bottom: -10px;
            left: 0;
        }

        .endDate {
            position: absolute;
            bottom: -10px;
            right: 0;
        }

        .activeDate {
            position: fixed;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            padding: 0.2em 0.8em;
            border-radius: 0.5em;
            font-size: large;
            font-weight: 600;
            text-transform: uppercase;
            background-color: #2254A9; /* Blue background color */
            color: #fff; /* Text color */
            border: 2px solid #082a64; /* Border color */
        }

        .timeline {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            i {
                width: 5px;
                height: 100%;
                background: #18427d;
                margin: auto;
                transition: 200ms ease;
                cursor: pointer;
            }

            .active {
                height: 100%;
                width: 10px;
                background: $accent;
            }
        }
    }

    .timeseriesItem {}
}

.createTimeSeries {
    position: relative;

    h2 {
        margin: 10px;
    }

    select,
    input {
        border: 1px solid $accent;
        padding: 8px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        font-size: small;
    }

    .selectBox {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 5px;

        button {
            padding: 10px;
            background: #2354AA;
            color: white;
            border: none;
        }
    }

    .itemContainer {
        height: 100%;
        overflow-y: auto;
        padding: 10px 0 10px 0;

        .timeseriesItem {
            display: grid;
            grid-template-columns: 1fr auto auto auto;
            gap: 5px;
            margin-bottom: 10px;

            p {
                font-size: small;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            h6 {
                font-weight: 400;
                margin-top: 5px;
                color: gray;
            }

            .fa {
                padding: 10px;
                margin: auto;
                display: block;
                cursor: pointer;
                color: $accent;
                background-color: antiquewhite;
                border-radius: 3px;
                height: fit-content;
                margin: auto;
            }

            .fa:hover {
                color: $primary;
            }

        }

    }
}