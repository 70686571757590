.button{
    background-color: #0C164F;
    height: 40px;
    width: 110px;
    color: white;
    border: none;
    border-radius: 10px;
}

.attention {
    position: relative;
    background-color: #0C164F;
    border: white;
    border-width: 5px;
    border-color: white;
    min-height: 20px;
    padding: 10px 25px; 
}

.attention > a {
    color: white;
    text-decoration: none; 
}
