@import "../Styles/0_mediaquery";
@import "../Styles/1_variables";
@import "../Styles/variables";

.landingnew {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
 
  .floods{
    position: relative;
    // background-color: black;
    height: auto;
    width: auto;
  }
 



  .bg {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba($color: #000, $alpha: 0.4);
    transition: background-color 0.5s ease; /* Added transition for background-color */
  
    img {
      max-width: 100%;
      object-fit: cover;
      height: 100%;
      width: 100%;
      opacity: 1;
      transition: opacity 0.5s ease; /* Changed transition duration to 0.5s and added ease for smoother transition */
    }
   .fadeOut {
      opacity: 0;
    }
  }
  

  .nhero {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.2);
    display: flex;
    align-items: center;

    .s_hero {
      max-width: 80%;
      width: 100%;
      min-height: 65vh;
      height: 100%;
      margin: auto;
      height: fit-content;
      background-color: rgba($color: $primary, $alpha: 0.6);
      padding: 2em;
      border-radius: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em;

      .left {
        display: grid;
        grid-template-rows: repeat(3, auto);
        gap: 3em;
        color: white;
        height: fit-content;
        margin: auto 0 auto 0;

        h1 {
          font-size: 68px;
          text-transform: capitalize;
        }

        h1 {
          background-image: linear-gradient(
            -225deg,
            white 0%,
            wheat 29%,
            whitesmoke 67%,
            wheat 100%
          );
          background-size: auto auto;
          background-clip: border-box;
          background-size: 200% auto;
          color: #fff;
          background-clip: text;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: textclip 5s linear infinite;
          display: inline-block;
        }

        @keyframes textclip {
          to {
            background-position: 200% center;
          }
        }

        #text1,
        #text2 {
          position: absolute;
          width: 100%;
          display: inline-block;

          font-family: "Raleway", sans-serif;
          font-size: 80pt;

          text-align: center;

          user-select: none;
        }

        p {
          margin-top: 10px;
          color: white;
          font-size: large;
        }

        h4 {
          font-size: xx-large;
          color: white;
          font-weight: 500;

          span {
            color: white;
            font-weight: bold;
          }
        }

        button {
          padding: 16px;
          width: fit-content;
          min-width: 250px;
          display: block;
          cursor: pointer;
          color: white;
          border: none;
          background-color: $primary;
        }
        button:hover {
          background-color: $secondary;
          color: white;
        }
      }

      .cool_text {
        overflow: hidden;
        position: relative;
        height: fit-content;
        margin: auto;
        img {
          height: 44px;
          object-fit: contain;
        }
        .container {
          margin: auto;
          filter: url(#threshold) blur(0.6px);
        }

        .text1,
        .text2 {
          position: absolute;
          width: 100%;
          display: inline-block;
          font-family: "Raleway", sans-serif;
          font-size: 70pt;
          text-align: center;
          user-select: none;
          color: white;
        }
      }

      img {
        max-width: 100%;
        object-fit: contain;
        object-position: center;
      }

      @include maxwidth(mobile) {
        grid-template-columns: 1fr !important;
        min-height: 50vh;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          opacity: 0.5;
        }

        .left {
          
          h1{
            font-size: xx-large !important;
          }

          h4{
            font-size: medium !important;
          }
        }

        .cool_text {
          display: none;
        }
      }
    }
    .coffee-medium__smoke {
      width: 8px;
      height: 20px;
      position: absolute;
      border-radius: 5px;
      background-color: #b3aeae;
    }
    @keyframes smokeOne {
      0% {
        bottom: 20px;
        opacity: 0;
      }
      40% {
        right: 40vh;
        opacity: 0.5;
      }
      80% {
        bottom: 70vh;
        opacity: 0.3;
      }
      100% {
        bottom: 90vh;
        opacity: 0;
      }
    }
    @keyframes smokeTwo {
      0% {
        top: 40px;
        opacity: 0;
      }
      40% {
        left: 40vh;
        opacity: 0.5;
      }
      80% {
        top: 70vh;
        opacity: 0.3;
      }
      100% {
        top: 90vh;
        opacity: 0;
      }
    }
    .coffee-medium__smoke-one {
      opacity: 0;
      bottom: 50px;
      left: 10vw;
      animation: smokeOne 3s 5s radial infinite;
    }
    .coffee-medium__smoke-two {
      opacity: 0;
      top: 70px;
      left: 30vw;
      animation: smokeTwo 3s 5s linear infinite;
    }
    .coffee-medium__smoke-three {
      opacity: 0;
      bottom: 65px;
      right: 30vw;
      animation: smokeTwo 3s 6s linear infinite;
    }
    .coffee-medium__smoke-for {
      opacity: 0;
      bottom: 50px;
      right: 10vw;
      animation: smokeOne 3s 5s linear infinite;
    }

    @include maxwidth(tablet) {
      height: fit-content !important;
      padding-top: 100px !important;
      padding-bottom: 50px;

      .div2equal {
      }
    }
  }

  .lcontainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;

    .wrap {
      max-width: 60vw;
      width: 100%;
      height: fit-content;
      margin: auto;
      border-radius: 16px;
      overflow: hidden;

      .cf {
        position: relative;
        width: 100%;
        height: fit-content;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-transition: background-image 0.2s ease-in-out;
        transition: background-image 0.2s ease-in-out;

        .overlay {
          width: 100%;
          height: fit-content;
          background-color: rgba($color: #007bff, $alpha: 0.5);
          color: white;
          display: flex;
          align-items: center;

          .div2equal {
            width: 100%;
            height: fit-content;
            padding: 1em;
            margin: auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2em;

            h1 {
              font-style: normal;
              font-weight: 700;
              font-size: 2em;
              color: #ffffff;
              font-size: x-large;
            }

            h3 {
              font-style: normal;
              font-weight: 600;
              font-size: larger;
              color: wheat;
              margin: 1em 0 1em auto;
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: medium;
              text-align: left;
              color: #ffffff;
              margin: 1em 0 1em 0;
              max-width: 70%;
              line-height: 1.5;
            }

            button {
              border-radius: 4px;
              background-color: white;
              border: none;
              color: #030092;
              text-align: center;
              font-size: 14px;
              padding: 10px;
              transition: all 0.5s;
              cursor: pointer;
              margin: 5px;
            }

            button span {
              cursor: pointer;
              display: inline-block;
              position: relative;
              transition: 0.5s;
            }

            button span:after {
              content: "\00bb";
              position: absolute;
              opacity: 0;
              top: 0;
              right: -20px;
              transition: 0.5s;
            }

            button:hover span {
              padding-right: 25px;
            }

            button:hover span:after {
              opacity: 1;
              right: 0;
            }
          }
          .options {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1em;
            max-width: 85%;
            width: 100%;
            margin: 0 auto 44px auto;

            button {
              background-image: linear-gradient(
                to right,
                $accent 0%,
                #61045f 51%,
                $accent 100%
              );
              padding: 10px;
              text-align: center;
              text-transform: uppercase;
              transition: 0.5s;
              color: white;
              box-shadow: 0 0 20px #eee;
              border-radius: 10px;
              display: block;
              cursor: pointer;
              border: 2px solid #030092;
            }

            button:hover {
              color: white;
              border: 2px solid wheat;
              background-image: linear-gradient(
                to right,
                #61045f 0%,
                #aa076b 51%,
                #61045f 100%
              );
            }

            .active {
              color: white;
              border: 1px solid wheat;
              background-image: linear-gradient(
                to right,
                #61045f 0%,
                #aa076b 51%,
                #61045f 100%
              );
            }
          }
        }
      }

      @include maxwidth(tablet) {
        max-width: 85vw !important;

        .div2equal {
          gap: 1em !important;

          p {
            max-width: 100% !important;
          }
        }
      }

      @include maxwidth(mobile) {
        max-width: 85vw !important;

        h1 {
          display: none;
        }

        h3 {
          margin: 0 !important;
          padding: 0 !important;
          font-size: large !important;
        }

        button {
          font-size: x-small !important;
          padding: 10px !important;
        }

        .div2equal {
          display: block !important;
          gap: 0 !important;

          p {
            max-width: 100% !important;
            line-height: 1;
            font-size: small !important;
          }

          button {
            display: none;
          }
        }
        .options {
          grid-template-columns: repeat(2, 1fr) !important;
          gap: 10px !important;
          width: 100% !important;
        }
      }
    }
  }

  .themes{
    width: 100%;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%; /* Adjust the width as per your requirement */
    margin: 10px auto;
    z-index: 999999;
    margin-bottom: 1.5em;
    background-color: transparent;
    color: white;
    display: flex;
    flex-direction: row;

    p{
      background-color: rgba($color: $primary, $alpha: 0.8);
      text-align: center;
      padding: 10px;
      border-radius: 8px;
      border: #fff solid 1px;
      cursor: pointer;
      width: 100%; /* Adjusted width */
      margin: auto 10px;
      height: normal; /* Adjust the height as per your requirement */
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal; /* Same as the height */    
    }

    p:hover {
      background-color: $secondary;
    }

    @include maxwidth(smaller-desktop) {
      // display: none;
      font-size: small;
      p{
        padding: 5px;
      }
    }
    @include maxwidth(mobile) {
      display: none;
    }
  }
}
