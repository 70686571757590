$primarydark: #008B62;
$primarylight: #f8f9fa;
$primarylighter: #9ECDC0;
$secondarylight: #026749;
$tertiarylight: #EBFE80;
$accent: #E97434;
$bg: #f6fafd;

$backgroundgray: #D0D2DE;
$navpurple: #3e4197;
$darkgray: #000000