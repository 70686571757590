@import "./1_variables";
@import "./_0_mediaquery";

.data {
  height: 100vh;
  overflow-y: auto;
}

.dcontainer {
  max-width: 90%;
  width: 100%;
  margin: 4.5em auto 4.5em 5%;
}

.arc {
  padding: 1em;

  .slist {
    min-height: 70vh;
    height: fit-content;

    h1 {
      color: $secondary;
      font-size: xx-large;
      margin: 10px 0 10px 0;
      padding: 0 0 44px 0;
      position: relative;
      text-align: left !important;
      width: fit-content;
      max-width: 85%;
      margin: 1em auto 0 auto;
      display: block;
    }

    h1::before {
      content: "";
      position: absolute;
      left: 7%;
      bottom: 16px;
      width: 150px;
      height: 14px;
      transform: skew(-12deg) translateX(-50%);
      background: #ecc115;
      z-index: 2;
    }

    .scontainer {
      width: 100%;
      height: 100%;
      margin: auto;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 2em;
      padding: 0 0 2em 0;

      .left {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        height: fit-content;
        transition: position 0.3s ease-in-out;
        padding-top: 40px;

        .itemsContainer{
          display: flex;
          flex-direction: column;
          border: 1px solid #60606030;
          border-radius: 5px;
          height: fit-content;
          margin-top: 4px;
          transition: position 0.3s ease-in-out;
        }

        span{
          margin: 20px auto auto 10px;
          color: rgb(78, 78, 78);
          font-size: large;
          font-weight: 600;
        }

        h3 {
          padding: 4px;
          color: $primary;
        }

        hr {
          background-color: gray !important;
        }

        .category {
          border-bottom: 1px solid #60606030;
          cursor: pointer;
          p {
            color: gray;
            padding: 5px 20px;
          }
        }
        a{
          color: gray;
          padding: 5px 20px;
          border: 1px solid #60606030;
          border-bottom: 1px solid #60606030;
          text-decoration: none;
        }
        .activated{
          background-color: #60606030;
        }
        .category:hover {
          background-color: #60606030;
        }
      }

      .fixed {
        position: fixed !important;
        top: 10vh;
      }

      .smain {
        padding: 5px;
        height: 100%;

        .top {
          display: grid;
          grid-template-columns: 1fr auto auto;
          gap: 10px;
          margin-bottom: 10px;

          .yr {
            display: grid;
            grid-template-columns: auto auto;
            gap: 10px;
            width: fit-content;
            height: fit-content;

            h4 {
              padding: 0px !important;
              display: block;
              margin: auto;
              height: fit-content;
              font-weight: 400;
              font-size: small;
            }

            select {
              height: fit-content;
              padding: 5px;
              border-bottom: 1px solid orange;
              border-top: none;
              border-left: none;
              border-right: none;
              color: white;
              outline: none;
              background-color: $accent;
              cursor: pointer;
              margin: auto;
            }
          }

          form {
            display: grid;
            grid-template-columns: 1fr auto;
          }

          p {
            padding: 10px;
            margin: auto;
            width: 100%;
          }

          input {
            height: fit-content;
            padding: 5px;
            border-bottom: 1px solid orange;
            border-top: none;
            border-left: none;
            color: white;
            border-right: none;
            outline: none;
            background-color: $accent;
            cursor: pointer;
            margin: auto;
          }
          input::placeholder {
            opacity: 1;
            color: white;
          }

          i {
            height: fit-content;
            padding: 5px;
            background-color: transparent;
            color: white;
            text-align: center;
            border-radius: 5px;
            cursor: pointer;
            margin-left: -28px;
          }
        }

        hr {
          color: #60606030 !important;
        }

        .list {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2em;
        }

        .stk {
          position: relative;
          cursor: pointer;
          .badge {
            display: inline-block;
            padding: 0.2em 0.6em;
            border-radius: 0.8em;
            font-size: 8px;
            font-weight: bold;
            text-transform: uppercase;
            background-color: #2254A9; /* Blue background color */
            color: #fff; /* Text color */
            border: 1px solid #082a64; /* Border color */
          }
          .collectionLabel{
            position: absolute;
            z-index: 10;
            top: 30%;
            right: 50%;
            transform: translate(-50%,-50%);
            color: $accent;
            font-weight: bold;
            font-size: x-large;
            text-align: center;
          }

          &.col{
            .thumbnailContainer {
              position: relative;
              width: 100%;
              max-height: 100%;
              height: 300px;
              border-radius: 10px;
              z-index: -10;
              display: flex;
              .title{
                position: absolute;
                top: 0;
                right: 12px;
                font-weight: bolder;
                z-index: 12;
                color: #103374;
              }
              img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                border: none;
                box-shadow: none;
                &.thumbnail{
                  z-index: 7;
                }
                &.silhouette{
                  z-index: 10;
                }
              }
            }
          }

          .edit {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 99;
            background-color: white;
            cursor: pointer;
            padding: 5px;
            border-radius: 5px;
            color: $secondary;
          }
          .edit:hover {
            background-color: $secondary;
          }

          img {
            width: 100%;
            max-height: 100%;
            height: 300px;
            border-radius: 10px;
            object-fit: cover;
            object-position: top;
            border: 1px solid #60606030;
            box-shadow: 2px 2px 5px #60606050;
          }

          .tp {
            h3 {
              color: $secondary;
              display: block;
              margin: auto;
              width: 100%;
            }
          }

          .np {
            margin: -3em 1em 0 -1em;
            position: relative;
            background: linear-gradient(-60deg, #fdeedc 10%, $bg1);
            border-radius: 5px;
            padding: 1em;
            border: 1px solid #60606030;
            box-shadow: 1px 1px 5px #60606030;
            transition: transform 0.3s ease-in-out;

            h3 {
              color: black;
              display: block;
              margin: auto;
              width: 100%;
              font-weight: 500;
              font-size: small;
              overflow: hidden;
              line-height: 16px;
              height: 32px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            h4 {
              margin: 5px 0 5px 0;
              font-weight: 500;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              color: $primary;
              font-size: 12px;
            }

            p {
              font-size: x-small;
              margin: 3px 0 3px 0;
              overflow: hidden;
              line-height: 14px;
              height: 56px;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              line-clamp: 4;
              -webkit-box-orient: vertical;
            }

            a {
              display: block;
              margin-left: auto;
              width: fit-content;
              cursor: pointer;
              text-decoration: none;
              font-size: small;
              color: blue;
              i {
                color: blue;
              }
            }
            a:hover {
              color: blueviolet !important;
              i {
                color: blueviolet !important;
              }
            }
          }

          p {
            line-height: 1.7;
            color: gray;
            font-size: small;
            margin-bottom: 10px;
          }

          a {
            display: block;
            margin-left: auto;
            width: fit-content;
            cursor: pointer;
            font-size: small;
            color: $secondary;
            text-decoration: none;
            i {
              color: $secondary;
            }
          }
          a:hover {
            i {
              color: $primary !important;
            }
          }
        }
        .stk:hover {
          .np {
            transform: translateX(1em);
          }
        }

        .nodata {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    @include maxwidth(mobile) {
      .scontainer {
        grid-template-columns: 1fr !important;

        .left {
          display: none;
        }
        .top {
          grid-template-columns: 1fr !important;
          p {
            display: none;
          }

          input {
            width: 100% !important;
          }
        }

        .list {
          grid-template-columns: 1fr !important;
        }
      }
    }
    @include maxwidth(tablet) {
      .scontainer {
        grid-template-columns: 1fr !important;

        .left {
          display: none;
        }
        .top {
          grid-template-columns: 1fr !important;
          p {
            display: none;
          }

          input {
            width: 100% !important;
          }
        }
        .list {
          grid-template-columns: 1fr !important;
        }
      }
    }
  }
}
