@import "./0_mediaquery.scss";
@import "./1_variables.scss";

.publications {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    position: relative;


    h3 {
      padding: 10px 0 10px 0;
      font-size: medium;
    }

    .content {
      min-height: 30vh;

      .accordion-body {
        margin: 5px 0 5px 0;
        border-radius: 8px;
        box-shadow: 1px 1px 5px #60606030;
        border: 1px solid #60606030;
        padding: 1em;

        h4 {
          font-weight: 600;
          margin-bottom: 10px;
          font-size: medium;
        }

        p {
          font-size: small;
        }

        .accordion-footer {
          width: fit-content;
          margin: 10px 0 0 auto;
          display: grid;
          grid-template-columns: auto auto;
          gap: 1em;

          a {
            text-decoration: none;
            font-size: medium;
            color: $primary;
            cursor: pointer;
          }

          a:hover {
            color: $secondary;
          }
        }
      }
    }

    .new {
      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 12px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid #60606070;
        border-top: 2px solid #60606070;
        cursor: pointer;
      }

      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }

      .usrinput {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        textarea {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }

        textarea:focus {
          border-bottom: 2px solid $primary;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }

        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .usrselect {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        select {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }

        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  .container {
    max-width: 85%;
    width: 100%;
    margin: 64px auto 1em auto;
    height: 100%;
    background-color: white;
    border-radius: 5px;


    .right {
      padding: 5px;
      height: 100%;

      .top {
        width: fit-content;
        margin-left: auto;
        margin-bottom: 10px;

        form {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-top: 10px;
        }

        p {
          padding: 10px;
          margin: auto;
          width: 100%;
        }

        input {
          padding: 8px;
          border: 1px solid $secondary;
          outline: none;
          border-radius: 5px;
          height: 32px;
          line-height: 32px;
          font-size: small !important;

        }

        i {
          background-color: $secondary;
          color: white;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
          width: 32px;
          height: 32px;
          line-height: 32px;
        }
      }

      .left {
        // box-shadow: 1px 1px 5px #60606010;
        background-color: $bg1;
        border-radius: 5px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1px;
        margin-bottom: 10px;

        h3 {
          padding: 4px;
          color: $primary;
        }

        hr {
          background-color: gray !important;
        }

        .category {
          border-bottom: 1px solid #60606030;
          cursor: pointer;

          p {
            color: gray;
            padding: 5px 20px;
          }
        }

        .category:hover {
          background-color: #60606030;
        }
      }


      hr {
        color: #60606030 !important;
      }

      .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        padding: 1em;
      }

      .stk {
        img {
          width: 100%;
          max-height: 100%;
          height: 250px;
          border-radius: 10px 10px 0 0;
          object-fit: cover;
          object-position: top;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606030;
        }

        .tp {
          h3 {
            color: $secondary;
            display: block;
            margin: auto;
            width: 100%;
          }
        }

        .np {
          margin: -5em 1em 0 1em;
          position: relative;
          background-color: white;
          border-radius: 5px;
          padding: 1em;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606030;

          h3 {
            color: $secondary;
            display: block;
            margin: auto;
            width: 100%;
            font-weight: 500;
            font-size: medium;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          h4 {
            margin: 5px 0 5px 0;
            font-weight: 500;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          p {
            line-height: 1.7;
            color: gray;
            font-size: small;
            margin-bottom: 10px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
          }

          a {
            display: block;
            margin-left: auto;
            width: fit-content;
            cursor: pointer;
            text-decoration: none;
            font-size: small;
            color: $secondary;

            i {
              color: $secondary;
            }
          }

          a:hover {
            i {
              color: blueviolet !important;
            }
          }
        }

        p {
          line-height: 1.7;
          color: gray;
          font-size: small;
          margin-bottom: 10px;
        }

        a {
          display: block;
          margin-left: auto;
          width: fit-content;
          cursor: pointer;
          font-size: small;
          color: $secondary;
          text-decoration: none;

          i {
            color: $secondary;
          }
        }

        a:hover {
          i {
            color: $primary !important;
          }
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .container {
      grid-template-columns: 1fr !important;

      .left {
        display: none;
      }

      .top {
        grid-template-columns: 1fr !important;

        p {
          display: none;
        }

        input {
          width: 100% !important;
        }
      }

      .list {
        grid-template-columns: 1fr !important;
      }
    }
  }

  @include maxwidth(tablet) {
    .container {
      grid-template-columns: 1fr !important;

      .left {
        display: none;
      }

      .top {
        grid-template-columns: 1fr !important;

        p {
          display: none;
        }

        input {
          width: 100% !important;
        }
      }

      .list {
        grid-template-columns: 1fr !important;
      }
    }
  }
}