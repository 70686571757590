@import "./0_mediaquery";
@import "./1_variables";

.InstancesPage {
  overflow-y: auto;
  position: relative;
  height: 100vh;
  padding-top: 52px;

  .catdata {
    max-width: 85%;
    width: 100%;
    margin: 1em auto 1em auto;
    min-height: 50vh;
  }

  .cat {
    h1 {
      font-size: xx-large;
      margin: 0 auto 1em auto;
      color: $primary;
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 4px solid $accent;
      width: fit-content;
      display: block;
    }

    p {
      text-align: center;
      font-size: medium;
      display: block;
      color: rgb(38, 38, 38);
      text-align: center;
      width: fit-content;
      display: block;
    }

    .mapcategory {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1em;
      margin-bottom: 2em;

      .thumbnail {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 1px 1px 5px #60606020;
        position: relative;
        cursor: pointer;

        img {
          height: calc(15vw * 0.6);
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .desc {
          background-color: rgba($color: $dark, $alpha: 0.4);
          padding: 8px;
          color: $dark;
          text-align: center;

          h4 {
            font-weight: 500;
            font-size: 10pt;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 5px;
            color: whitesmoke;
          }

          p {
            font-size: 8pt;
            color: whitesmoke;
          }
        }

        .options {
          background-color: rgba($color: $dark, $alpha: 1);
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          p {
            cursor: pointer;
            text-align: center;
            color: white;
            padding: 8px;
            font-size: 11pt;
          }
          p:hover {
            background-color: $accent;
          }
        }
      }
      .thumbnail:hover {
        box-shadow: 1px 1px 5px #60606070;
      }

      @include maxwidth(tablet) {
        grid-template-columns: repeat(1, 1fr) !important;
        img {
          height: calc(50vw * 0.6) !important;
        }
      }
      @include maxwidth(mobile) {
        grid-template-columns: repeat(1, 1fr) !important;
        img {
          height: calc(85vw * 0.6) !important;
        }
      }
    }
  }
}
