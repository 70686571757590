@import "./0_mediaquery.scss";
@import "./1_variables.scss";

.storymaps {
  height: 100%;
  width: 100%;
  padding-top: 60px;
  overflow-y: auto !important;

  .innercontent {
    max-width: 80%;
    width: 100%;
    margin: auto;
    min-height: 60vh;
    padding: 0 0 2em 0;
    background-color: white;
    border-radius: 5px;
    h1 {
      text-align: center;
      padding: 10px;
      border-bottom: 1px solid #60606020;
    }
    h4 {
      font-size: 22px;
      padding: 10px;
      margin-left: 20px;
    }
    h5 {
      font-size: 18px;
      margin: 30px 0 0 10px;
    }
    .theme {
      font-size: medium;
      font-weight: 600;
      color: #2254aa;
      padding: 10px;
      text-align: center;
    }
    p {
      padding: 10px;
      border-left: 2px solid #2254aa;
      line-height: 35px;
      text-align: justify;
      text-justify: inter-word;
    }
    img {
      width: 97%;
      border-radius: 5px;
      display: block;
      margin: 10px auto;
      object-fit: contain;
    }
  }
}
