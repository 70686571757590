@import "../../Styles/0_mediaquery";
@import "../../Styles/1_variables";

#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardSwiper {
  width: 20vw;
  max-height: 50vh;
  height: 100%;

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: rgb(0, 140, 255);

    h4 {
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 45%;
      text-align: center;
      color: white;
      font-size: large;
      background-color: rgba($color: #000000, $alpha: 0.4);
    }
  }

  @include maxwidth(tablet) {
    max-height: 30vh;
    width: 30vw;
  }
  @include maxwidth(mobile) {
    height: 150px !important;
    width: 50vw;
    

    h4 {
      font-size: small !important;
    }
  }
}
