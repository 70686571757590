@import "./1_variables";
@import "./_0_mediaquery";

.feedbackform{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    z-index: 9999;
    animation: scale-in 400ms;
    @keyframes scale-in {
        0% {
        background-color: rgba($color: #000000, $alpha: 0);
        opacity: 0;
        transform: scale(0);
        }
        80% {
        background-color: rgba($color: #000000, $alpha: 0.1);
        }
        100% {
        background-color: rgba($color: #000000, $alpha: 0.5);
        opacity: 1;
        transform: scale(1);
        }
    }
    .ff_container{
        background-color: white;
        border-radius: 8px;
        padding: 1em 2em 1em 2em;
        margin: 0 auto 0 auto;
        color: #000000;
        max-width: 400px;
        max-height: 70%;
        overflow-y: auto;
        width: 100%;

        /* width */
        &::-webkit-scrollbar {
        width: 10px;
        border-radius: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 8px;
        }
        
        input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
        }
        input:focus {
            border-bottom: 2px solid $primary;
        }
        textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
        }
        textarea:focus {
            border-bottom: 2px solid $primary;
        }
        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 12px;
          min-width: 250px;
          width: fit-content;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
    }
}